<template>
  <div v-if="popupActive" class="nav-switch-org" style="min-width: 253px">
    <div
      class="nav-switch-org-header p-4 px-6 flex justify-between items-center"
    >
      Swap Workspace
      <x-icon size="1.5x" class="custom-class" @click="modal" color="gray" />
    </div>
    <div
      v-for="item in organizations"
      :key="item._id"
      class="flex justify-between items-center nav-item-hover"
      @click="orgChanged(item)"
    >
      <div class="w-full px-6">
        <div class="flex justify-between nav-item items-center">
          <div class="nav-item-content">
            <div class="nav-item-content-title">
              {{ item.name | capitalize }}
            </div>
            <div class="nav-item-content-role">
              {{ ("Your Role: " + item.role) | capitalize }}
            </div>
          </div>
          <vs-icon
            v-if="item._id === activeOrganizationId"
            @click="popupActive = true"
            icon-pack="material-icons"
            size="1.3rem !important"
            icon="check"
            color="#47E7B8"
          />
        </div>
      </div>
    </div>
    <div class="nav-item-manage px-6">
      <a @click="mannageOrganization">Manage</a>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { XIcon } from "vue-feather-icons";
import { mapActions } from "vuex";

export default {
  name: "v-nav-switch-org",
  props: ["popupActive", "modal"],
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  data() {
    return {
      activeOrganizationId: null,
      activeOrganizationName: "N/A",
      organizations: [],
    };
  },
  methods: {
    ...mapActions("organization", ["fetchOrganizationClinicsList"]),
    async orgChanged(org) {
      // user.userType = org.role;
      const oldRole = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );
      if (
        org._id !==
        localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        )
      ) {
        localStorage.setItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`,
          org.role
        );
        localStorage.setItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`,
          org._id
        );
        this.activeOrganizationId = org._id;
        this.activeOrganizationName = org.name;

        try {
          if (org.role === "Org Owner" || org.role === "Staff") {
            const res = await this.fetchOrganizationClinicsList({
              id: org._id,
              limit: 200,
              sort: "clinicName",
              dir: "asc",
            });
            const clinics = res.data.data.docs
              .filter(({ showStore }) => showStore)
              .map(
                ({
                  _id,
                  clinicName,
                  clinicOpeningHours,
                  address,
                  paymentTypes,
                }) => ({
                  _id,
                  clinicName,
                  clinicOpeningHours,
                  address,
                  paymentTypes,
                })
              );
            if (clinics.length) {
              sessionStorage.setItem(
                "doctorClinicSelectedClinic",
                clinics[0]._id
              );
            } else {
              sessionStorage.clear();
            }
            if (oldRole !== org.role) {
              this.$router.push("login");
            } else {
              location.reload();
            }
          } else {
            if (oldRole !== org.role) {
              if (
                this.$store.state.AppActiveUser.userRole.toLowerCase() ==
                "nurse"
              ) {
                this.$router.push(
                  process.env.VUE_APP_REGION !== "US"
                    ? "/nurse/notice-board"
                    : "/nurse/patients"
                );
              } else {
                this.$router.push(
                  process.env.VUE_APP_REGION !== "US"
                    ? "/doctor/notice-board"
                    : "/doctor/treatment-history"
                );
              }
            } else {
              location.reload();
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    mannageOrganization() {
      window.open("/select-workspace", "_blank");
    },
  },
  created() {
    const user = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    this.activeOrganizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    if (this.activeOrganizationId) {
      this.activeOrganizationName =
        user.organizations[
          _.findIndex(user.organizations, ["_id", this.activeOrganizationId])
        ].name;
    }
    this.organizations = user.organizations;
  },
  components: {
    XIcon,
  },
};
</script>
