<template>
  <div class="centerx" v-if="this.organizations.length > 0">
    <!-- <div class="mask" @click="closeModal" v-if="popupActive == true" /> -->
    <div class="flex items-center max-w-full pt-4 pb-4 justify-between">
      <div class="flex flex-no-wrap center" style="max-width: 92%">
        <h5
          style="
            overflow: hidden;
            text-overflow: ellipsis;
            color: #0e4333;
            font-size: 13px;
          "
        >
          {{ this.activeOrganizationName }}
        </h5>
      </div>

      <div class="flex justify-between items-center btn-circle">
        <chevron-down-icon v-if="popupActive == false" @click="openModal" />
        <chevron-up-icon v-if="popupActive == true" @click="openModal" />
      </div>
    </div>
    <div
     v-if="popupActive"
      id="popUpMask"
      @click="closeModal"
      style="padding-top: 9rem"
      class="h-screen w-screen absolute z-10 top-0 left-0 pl-5"
    >
      <v-nav-switch-org
        v-if="popupActive"
        :popupActive="this.popupActive"
        :modal="closeModal"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { ChevronDownIcon, ChevronUpIcon, XIcon } from "vue-feather-icons";
import VNavSwitchOrg from "./switch-org";
import "./style.scss";

export default {
  data() {
    return {
      organizations: [],
      popupActive: false,
    };
  },
  created() {
    const user = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    this.activeOrganizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    if (this.activeOrganizationId) {
      this.activeOrganizationName =
        user.organizations[
          _.findIndex(user.organizations, ["_id", this.activeOrganizationId])
        ].name;
    }
    this.organizations = user.organizations;
  },
  mounted() {
    if(this.organizations.length > 0){
      document.documentElement.style.setProperty('--SelectOrganizationHeight', `54px`)
    }
  },
  methods: {
    openModal(e) {
      e.preventDefault();
      this.popupActive = true;
    },
    closeModal(e) {
      e.preventDefault();
      this.popupActive = false;
    },
  },
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    VNavSwitchOrg,
  },
};
</script>
