<template>
  <div
    class="the-navbar__user-meta flex items-center"
    v-if="activeUserInfo.displayName"
  >
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
      <!-- <small>Available</small>  -->
    </div>

    <div closable class="cursor-pointer profile">
      <div class="con-img ml-3">
        <div class="profile-header-image">
          <img
            v-if="activeUserInfo.photoURL"
            key="onlineImg"
            :src="activeUserInfo.photoURL"
            alt="user-img"
            class="img-fluid"
          />
        </div>
      </div>

      <div class="menu-list">
        <div class="menu-space" />
        <ul style="min-width: 10rem">
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary hover:text-white
            "
            @click="
              $router.push({ name: 'super-admin-my-account' }).catch(() => {})
            "
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">My Account</span>
          </li>
          <!-- <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/change-password').catch(() => {})">
            <feather-icon icon="KeyIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Change Password</span>
          </li>-->
          <!--<li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/profile').catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/email').catch(() => {})">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/todo').catch(() => {})">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/chat').catch(() => {})">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/eCommerce/wish-list').catch(() => {})">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Wish List</span>
          </li>
          -->
          <vs-divider class="m-1" />

          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary hover:text-white
            "
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {};
  },
  props: {
    profileRouteName: {
      type: String,
      default: "admin-profile",
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapMutations(["UPDATE_CLINIC_ID"]),
    ...mapMutations("ecommerce", ["REMOVE_ALL_ITEMS_IN_CART"]),
    logout() {
      // if user is logged in via auth0
      //if (this.$auth.profile) this.$auth.logOut();

      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser;

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push("/login").catch(() => {});
          });
      }

      // If JWT login

      if (
        localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        )
      ) {
        sessionStorage.removeItem('doctorClinicSelectedClinic');
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}TokenExpiry`
        );
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`);
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
        );

        this.$cookies.remove(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
        );

        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`);
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );
        localStorage.removeItem("xeroConnectionStatus");

        // clear localstorage selected ids
        localStorage.removeItem('selectedOrg')
        localStorage.removeItem('selectedIds')

        // clear selected clinic state
        this.UPDATE_CLINIC_ID({
          clinicId: null,
          clinic: null,
          operations: null
        });

        // clear cart items state
        this.REMOVE_ALL_ITEMS_IN_CART()

        this.$router.push("/login").catch(() => {});
      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change("admin");
      localStorage.removeItem("userInfo");

      localStorage.removeItem("xeroConnectionStatus");

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push("/login").catch(() => {});
    },
  },
};
</script>

<style scoped>
.menu-list {
  display: none;
  position: absolute;
  border: 1px solid #e3eae8;
  background-color: white;
  border-radius: 5px;
  top: 50px;
  right: 0;
}
.menu-list:before {
  content: "";
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  right: 10px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid#e3eae8;
  clear: both;
}
.menu-list:after {
  content: "";
  position: absolute;
  top: -8px;
  right: 12px;
  width: 0;
  height: 0;
  border-bottom: 8px solid white;
  clear: both;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.menu-space {
  position: absolute;
  width: 100%;
  height: 65%;
  top: -50px;
}
.profile:hover .menu-list {
  display: block;
  z-index: 60000;
}
.profile-list:hover > span {
  color: white;
}
.profile-list:hover {
  background-color: #3dc9b3;
  border-radius: 4px 4px 0px 0px;
}
.profile-list:hover .menu-list:after {
  border-bottom: 8px solid #3dc9b3;
  content: "";
  position: absolute;
  top: -8px;
  right: 12px;
  width: 0;
  height: 0;
  /* border-bottom: 8px solid white; */
  clear: both;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
</style>
